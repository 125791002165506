import React from 'react'
import Helmet from 'react-helmet'

import PetSOSBanner from '../assets/images/fulls/petsos.png'

import Gallery from '../components/Gallery'
import Layout from '../components/layout'

const DEFAULT_IMAGES = [
  {
    id: '1',
    source: PetSOSBanner,
    thumbnail: PetSOSBanner,
    caption: 'PetSOS',
    description: 'A cross platform mobile app for lost and found pets.',
  },
]

const HomeIndex = () => {
  const siteTitle = 'Tim Optimum - Home'
  const siteDescription = 'Domača stran Tim Optimum'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
      </Helmet>

      <div id="main">
        <section id="home">
          <header className="major">
            <h1>
              Tim Optimum
              <br />
              Ideas made into Reality
            </h1>
          </header>
          <p>
            Our goal is to help people in their everyday life, by making
            necessary technological solutions. We want to expand on our ideas
            with feedback from our target customers and make the vision into
            reality.
          </p>
        </section>

        <section id="work">
          <h2>Our Work</h2>

          <Gallery
            images={DEFAULT_IMAGES.map(
              ({ id, source, thumbnail, caption, description }) => ({
                source,
                thumbnail,
                caption,
                description,
              })
            )}
          />

          {DEFAULT_IMAGES.length > 3 && (
            <ul className="actions">
              <li>
                <a href="#" className="button">
                  Full Portfolio
                </a>
              </li>
            </ul>
          )}
        </section>

        <section id="contact">
          <h2>Get In Touch</h2>
          <p>
            You can write us a mail or contact us using the information below.
          </p>
          <div className="row">
            <div className="8u 12u$(small)">
              <form method="post" action="#">
                <div className="row uniform 50%">
                  <div className="6u 12u$(xsmall)">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Name"
                    />
                  </div>
                  <div className="6u 12u$(xsmall)">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="12u">
                    <textarea
                      name="message"
                      id="message"
                      placeholder="Message"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
                <ul className="actions" style={{ marginTop: 30 }}>
                  <li>
                    <input type="submit" value="Send Message" />
                  </li>
                </ul>
              </form>
            </div>
            <div className="4u 12u$(small)">
              <ul className="labeled-icons">
                <li>
                  <h3 className="icon fa-home">
                    <span className="label">Address</span>
                  </h3>
                  Tim Optimum d.o.o.
                  <br />
                  Sagadinova 15a
                  <br />
                  2250 Ptuj
                  <br />
                  Slovenia
                  <br />
                  SI 78898838
                </li>
                <li>
                  <h3 className="icon fa-mobile">
                    <span className="label">Phone</span>
                  </h3>
                  +386 70 824 730
                  <br /> +386 31 348 682
                </li>
                <li>
                  <h3 className="icon fa-envelope-o">
                    <span className="label">Email</span>
                  </h3>
                  <a href="#">martin@timoptimum.com</a>
                  <br />
                  <a href="#">klemen@timoptimum.com</a>
                  <br />
                  <a href="#">domen@timoptimum.com</a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default HomeIndex
